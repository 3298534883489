import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { AppStorageService, StorageLocation } from '../app-store/app-storage.service';
import { environment } from '@config/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppRoutingService {
  constructor(
    private readonly router: Router,
    private readonly storage: AppStorageService
  ) { }

  /**
   * Chuyển hướng đến trang trước đó.
   * Nếu không tìm thấy URL trước đó, chuyển hướng đến trang chủ mặc định.
   */
  navigateToPreviousPage() {
    const previousUrl = this.storage.getItem<string>(
      this.storage.localStorageKeys.global.routing.previousUrl,
      { location: StorageLocation.LOCAL_STORAGE }
    );
    window.location.href = previousUrl ?? '';
  }

  /**
   * Chuyển hướng đến trang chủ.
   */
  navigateToHomePage(): void {
    this.router.navigate(['']);
  }

  /**
   * Chuyển hướng đến trang đăng nhập.
   */
  navigateToLoginPage(): void {
    if (!_.isNil(environment.domain.urlWebLogIn) && !_.isEmpty(environment.domain.urlWebLogIn)) {
      window.location.href = environment.domain.urlWebLogIn;
    }
    this.router.navigate(['login']);
  }

  navigateToLoginPageAsync() {
    if (!_.isNil(environment.domain.urlWebLogIn) && !_.isEmpty(environment.domain.urlWebLogIn)) {
      return window.location.href = environment.domain.urlWebLogIn;
    }
    return this.router.navigate(['login']);
  }

  /**
   * Chuyển hướng đến trang lỗi truy cập không được phép (403).
   */
  navigateToUnauthorizedPage(): void {
    this.router.navigateByUrl('error?s=403');
  }

  /**
   * Chuyển hướng đến trang lỗi máy chủ nội bộ (500).
   */
  navigateToInternalServerErrorPage(): void {
    this.router.navigateByUrl('error?s=500');
  }

  /**
   * Chuyển hướng đến trang không tìm thấy (404).
   */
  navigateToNotFoundPage(): void {
    this.router.navigateByUrl('error?s=404');
  }
}
